import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import {
  GetIsContactsDialogOpenSelector,
  GetSelectedItemSelector,
} from "../store/main.selector";
import { useSelector } from "react-redux";
import { store } from "../store/store";
import { setIsContactsDialogOpenAction } from "../store/main.slice";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Footer from "../components/Footer";

const ContactsDialog = ({ id, isMobile }) => {
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState(false);
  const theme = useTheme();
  const isContactsDialogOpen = useSelector(GetIsContactsDialogOpenSelector);
  const selectedItemSelector = useSelector(GetSelectedItemSelector);

  useEffect(() => {
    if (
      selectedItemSelector != undefined &&
      selectedItemSelector.name != null
    ) {
      setProduct(selectedItemSelector.name);
    }
  }, [selectedItemSelector]);

  useEffect(() => {
    if (isContactsDialogOpen != undefined) {
      setOpen(isContactsDialogOpen);
    }
  }, [isContactsDialogOpen]);

  const handleClose = () => {
    store.dispatch(setIsContactsDialogOpenAction({ open: false }));
  };

  return (
    <Dialog open={open} maxWidth="lg" aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        <div className="dialogHeaderContainer">
          <span> פרטים ליצירת קשר</span>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="dialogContentContainer">
          <Footer isFromDialog={true} isMobile={false} _product={product} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ContactsDialog;
