import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
// import logo from "../images/RensansLogo.png";
import logo from "../images/renesans-logo.jpg";
import {
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { Box, keyframes } from "@mui/system";
import Social from "./Social";

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Header = ({ menuList, isMobile }) => {
  const [value, setValue] = React.useState("0");
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    navigate("../" + value, { replace: true });
  }, [value]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const getBottomsHeaderStyle = () => {
    if (!isMobile) {
      return {
        "& .MuiBottomNavigationAction-label": {
          fontSize: "1rem",
          fontWeight: "bold",
          minWidth: "120px",
        },
        "& .MuiBottomNavigationAction-label.Mui-selected": {
          fontSize: "1.2rem",
          fontWeight: "bold",
          minWidth: "120px",
          color: "#40b0ef",
        },
      };
    } else {
      return {
        // position: "fixed",
        // top: "50px",
        // display: "flex",
        // // fontSize: "0.8rem",
        // background: "red",
        // justifyContent: "space-around",
        // // zIndex: "1299",
        padding: "0px",
        minWidth: "auto",
        width: "auto",
        "& .MuiBottomNavigation-root": {
          height: "0px",
        },
        "& .MuiBottomNavigationAction-label": {
          fontSize: "0.8rem",
          fontWeight: 300,
        },
        "& .Mui-selected": {
          fontSize: "0.85rem",
          fontWeight: 300,
          color: "white",
          padding: "0px",
          borderRadius: "0px",
        },
      };
    }
  };

  return (
    menuList && (
      <div className="App-header">
        {isMobile && (
          <IconButton size="large" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        )}

        {/* {isMobile && (
          <div className="mobileMenu">
            {menuList.map((item) => (
              <Link
                label={item.name}
                key={item._id}
                onClick={() => {
                  navigate("../" + item._id, { replace: true });
                }}
              >
                {item.name}
              </Link>
            ))}
          </div>
        )} */}

        <BottomNavigation
          className={isMobile ? "mobileMenu" : null}
          showLabels={true}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            className={isMobile ? "mobileMenuHeader" : null}
            to={"/0"}
            icon={
              <img
                className={
                  isMobile ? "mobileMenuHeaderImage" : "menuHeaderImage"
                }
                src={logo}
              />
            }
          />

          {menuList.map((item) => (
            <BottomNavigationAction
              sx={getBottomsHeaderStyle()}
              value={item._id}
              key={item._id}
              to={"/" + item._id}
              label={item.name}
            />
          ))}
        </BottomNavigation>
        {/* , animation: `${blink} 2s linear infinite` */}
        <Social />
        <Drawer
          sx={{ "z-index": 9999 }}
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer}
        >
          <Box
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
          >
            <List>
              {menuList.map((item) => (
                <ListItem
                  key={item._id}
                  disablePadding
                  onClick={() => {
                    setValue(item._id);
                  }}
                >
                  <ListItemButton>
                    {/* <ListItemIcon>                
              </ListItemIcon> */}

                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Box>
        </Drawer>
      </div>
    )
  );
};

export default Header;
