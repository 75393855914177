import { createSelector } from "@reduxjs/toolkit";

const selectSelf = (state) => state;

export const GetCategoriesSelector = createSelector(selectSelf, (state) => {
  return state.main.Categories;
});

export const GetSubCategoriesDetailsForRoutesSelector = createSelector(
  selectSelf,
  (state) => {
    return state.main.SubCategoriesDetailsForRoutes;
  }
);

export const GetCurrentSubCategoryIdSelector = createSelector(
  selectSelf,
  (state) => {
    return state.main.currentSubCategoryId;
  }
);

export const GetSubCategoriesSelector = createSelector(selectSelf, (state) => {
  return state.main.SubCategories;
});

export const GetSubCategoriesDetailsSelector = createSelector(
  selectSelf,
  (state) => {
    return state.main.SubCategoriesDetails;
  }
);

export const GetSettingsSelector = createSelector(selectSelf, (state) => {
  return state.main.SubCategories;
});

export const GetisItemDialogOpenSelector = createSelector(
  selectSelf,
  (state) => {
    return state.main.Settings.isItemDialogOpen;
  }
);

export const GetSelectedItemSelector = createSelector(selectSelf, (state) => {
  return state.main.Settings.selectedItem;
});

export const GetIsContactsDialogOpenSelector = createSelector(
  selectSelf,
  (state) => {
    return state.main.isContactsDialogOpen;
  }
);

export const GetIsSearchDialogOpenSelector = createSelector(
  selectSelf,
  (state) => {
    return state.main.Search.isSearchDialogOpen;
  }
);
export const GetSearchResultsSelector = createSelector(selectSelf, (state) => {
  return state.main.Search.SearchResults;
});
export const GetTermSearchedSelector = createSelector(selectSelf, (state) => {
  return state.main.Search.termSearched;
});
