import React from "react";
import { store } from "../store/store";
import { setSettingsAction } from "../store/main.slice";
import { getEmbeddFromUrl } from "../Helpers/utils";
import { useNavigate } from "react-router-dom";

const OneCategoryDetail = ({ item }) => {
  let navigate = useNavigate();

  const handleSelectItem = (item) => {
    navigate("../" + item._id);
    store.dispatch(
      setSettingsAction({ isItemDialogOpen: true, selectedItem: item })
    );
  };

  return (
    <div
      className="image-item"
      key={item._id}
      onClick={(e) => handleSelectItem(item)}
    >
      <img
        src={getEmbeddFromUrl(item.photos[0])}
        alt="../loading-load.gif"
        onError={(e) => {
          e.target.src = "../loading-load.gif";
        }}
        loading="lazy"
      />
      <div className="overlay">
        <span>{item.name}</span>
      </div>
    </div>
  );
};

export default OneCategoryDetail;
