import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./components/Main";
import { useMediaQuery } from "react-responsive";
import { Outlet, Route, Routes } from "react-router-dom";
import MainContent from "./pages/MainContent";
import Home from "./components/Home";
import { createTheme, ThemeProvider } from "@mui/material";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { GetCategories } from "./proxy/http.proxy";
import { useSelector } from "react-redux";
import {
  GetCategoriesSelector,
  GetSubCategoriesDetailsForRoutesSelector,
} from "./store/main.selector";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CategoriesDetailsDialog from "./pages/CategoriesDetailsDialog";
import CategoriesDetails from "./pages/CategoriesDetails";
import ContactsDialog from "./pages/ContactsDialog";
import SearchDialog from "./pages/SearchDialog";

const theme = createTheme({ direction: "rtl" });

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const usePreventScroll = (preventScrollRef) => {
  useEffect(() => {
    const preventScrolling = (e) => {
      if (preventScrollRef.current) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", preventScrolling, {
      passive: false,
    });
    return () => document.removeEventListener("touchmove", preventScrolling);
  }, []);
};

const App = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const categories = useSelector(GetCategoriesSelector);
  const subCategoriesDetailsForRoutes = useSelector(
    GetSubCategoriesDetailsForRoutesSelector
  );

  const [categoriesState, setCategoriesState] = useState([]);
  const [subCategoriesDetails, setSubCategoriesDetails] = useState([]);

  useEffect(() => {
    GetCategories();
  }, []);

  useEffect(() => {
    if (categories != undefined && Array.isArray(categories)) {
      setCategoriesState(categories);
    }
  }, [categories]);

  useEffect(() => {
    if (
      subCategoriesDetailsForRoutes != undefined &&
      Array.isArray(subCategoriesDetailsForRoutes)
    ) {
      setSubCategoriesDetails(subCategoriesDetailsForRoutes);
    }
  }, [subCategoriesDetailsForRoutes]);

  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", "rtl");
  }, []);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        {categoriesState && subCategoriesDetails && (
          <div className="App">
            <Header isMobile={isMobile} menuList={categoriesState} />
            <Main />
            <Routes>
              <Route path={"/0"} element={<Home />} />
              {categoriesState.map((item, index) => (
                <Route
                  key={index}
                  path={"/" + item._id}
                  element={<MainContent isMobile={isMobile} item={item} />}
                />
              ))}
              {/* {subCategoriesDetails.map((item, index) => (
                <Route
                  key={index}
                  path={"/" + item._sub_category_id}
                  element={
                    <CategoriesDetails subCategoryId={item._sub_category_id} />
                  }
                />
              ))} */}
              {subCategoriesDetails.map((item, index) => (
                <Route
                  key={index}
                  path={"/" + item._id}
                  element={
                    <CategoriesDetailsDialog
                      id={item._id}
                      isMobile={isMobile}
                    />
                  }
                />
              ))}
            </Routes>
            <Footer isMobile={isMobile} />
            <ContactsDialog />
            <SearchDialog isMobile={isMobile} />
          </div>
        )}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={true}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
