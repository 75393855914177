import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OneCategoryDetail from "../components/OneCategoryDetail";
import { GetSubCategoriesDetails } from "../proxy/http.proxy";
import { GetSubCategoriesDetailsSelector } from "../store/main.selector";

const CategoriesDetails = ({ subCategoryId }) => {
  const [subCategoriesStateDetails, setSubCategoriesStateDetails] = useState(
    []
  );
  const subCategoriesDetails = useSelector(GetSubCategoriesDetailsSelector);

  useEffect(() => {
    if (
      subCategoriesDetails != undefined &&
      Array.isArray(subCategoriesDetails)
    ) {
      setSubCategoriesStateDetails(subCategoriesDetails);
    }
  }, [subCategoriesDetails]);

  useEffect(() => {
    if (subCategoryId != undefined) {
      GetSubCategoriesDetails(subCategoryId);
    }
  }, [subCategoryId]);

  return (
    <div className="container">
      {subCategoryId && (
        <div>
          <div className="image-gallery">
            <div className="column">
              {subCategoriesStateDetails &&
                subCategoriesStateDetails.map((item, index) => (
                  <OneCategoryDetail item={item} key={index} />
                ))}
            </div>
          </div>
          {/* <CategoriesDetailsDialog /> */}
        </div>
      )}
    </div>
  );
};

export default CategoriesDetails;
