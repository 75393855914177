import axios from "axios";
import {
  setCategoriesAction,
  setIsSearchDialogOpenAction,
  setSearchResultsAction,
  setSubCategoriesAction,
  setSubCategoriesDetailsAction,
  subCategoriesDetailsForRoutesAction,
} from "../store/main.slice";
import { store } from "../store/store";

const GetOutUrl = () => {
  if (window.location.href.includes("localhost")) {
    return "http://localhost:3500";
  } else {
    return "http://" + window.location.hostname + ":3500";
  }
};

export const GetCategories = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(GetOutUrl() + "/getCategories")
      .then((res) => {
        store.dispatch(setCategoriesAction(res.data.categories));
        store.dispatch(
          subCategoriesDetailsForRoutesAction(res.data.subCategoriesDetails)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const GetSubCategories = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(GetOutUrl() + "/getSubCategories/", { params: { id } })
      .then((res) => {
        store.dispatch(setSubCategoriesAction(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const GetSubCategoriesDetails = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(GetOutUrl() + "/getSubCategoriesDetails/", { params: { id } })
      .then((res) => {
        store.dispatch(setSubCategoriesDetailsAction(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
export const SearchSubCategoriesDetails = (query) => {
  return new Promise((resolve, reject) => {
    axios
      .get(GetOutUrl() + "/SearchSubCategoriesDetails/", { params: { query } })
      .then((res) => {
        store.dispatch(setSearchResultsAction({ results: res.data }));
        store.dispatch(setIsSearchDialogOpenAction({ open: true }));
      })
      .catch((err) => {
        store.dispatch(setSearchResultsAction({ results: [] }));
        store.dispatch(setIsSearchDialogOpenAction({ open: true }));

        console.log(err);
      });
  });
};

export const AddContact = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(GetOutUrl() + "/AddContact", { ...data })
      .then((res) => {
        resolve();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
