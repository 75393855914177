import React, { useEffect, useState } from "react";
import { GetSubCategories } from "../proxy/http.proxy";
import {
  GetCurrentSubCategoryIdSelector,
  GetSelectedItemSelector,
  GetSubCategoriesSelector,
} from "../store/main.selector";
import { useSelector } from "react-redux";
import {
  BottomNavigation,
  BottomNavigationAction,
  Link,
  Typography,
} from "@mui/material";
import CategoriesDetails from "./CategoriesDetails";
import { useNavigate } from "react-router-dom";
import { setCurrentSubCategoryIdAction } from "../store/main.slice";
import { store } from "../store/store";

const MainContent = ({ item, isMobile }) => {
  const [value, setValue] = React.useState();
  const [subCategoriesState, setSubCategoriesState] = useState([]);

  const subCategories = useSelector(GetSubCategoriesSelector);
  const getCurrentSubCategoryId = useSelector(GetCurrentSubCategoryIdSelector);
  const selectedItem = useSelector(GetSelectedItemSelector);

  let navigate = useNavigate();

  useEffect(() => {
    if (subCategories != undefined && Array.isArray(subCategories)) {
      setSubCategoriesState(subCategories);
    }
  }, [subCategories]);

  useEffect(() => {
    setValue(undefined);
    GetSubCategories(item._id);
  }, [item]);

  useEffect(() => {
    if (
      selectedItem != undefined &&
      selectedItem._sub_category_id != undefined
    ) {
      setTimeout(() => {
        setValue(selectedItem._sub_category_id);
      }, 750);
    }
  }, [selectedItem]);

  const updateCategoryId = (id) => {
    store.dispatch(setCurrentSubCategoryIdAction({ id: undefined }));
  };

  useEffect(() => {
    if (
      subCategoriesState != undefined &&
      Array.isArray(subCategoriesState) &&
      subCategoriesState.length > 0
    ) {
      setValue(subCategoriesState[0]._id);
    }
  }, [subCategoriesState]);

  const getBottomsHeaderStyle = () => {
    if (!isMobile) {
      return {
        "& .MuiBottomNavigationAction-label": {
          fontSize: "1rem",
          fontWeight: 300,
        },
        "& .Mui-selected": {
          fontSize: "1rem",
          fontWeight: 300,
          color: "white",
          backgroundColor: "#0c0c0c",
          padding: "10px",
          borderRadius: "6px",
        },
      };
    } else {
      return {
        padding: "0px",
        minWidth: "auto",
        width: "auto",
        "& .MuiBottomNavigationAction-label": {
          fontSize: "0.8rem",
          fontWeight: 300,
        },
        "& .Mui-selected": {
          fontSize: "0.85rem",
          fontWeight: 300,
          color: "white",
          backgroundColor: "#0c0c0c",
          padding: "0px",
          borderRadius: "0px",
        },
      };
    }
  };

  return (
    <div className="App-main">
      {/* <Typography variant="h5" component="h5">
        {item.name}
      </Typography> */}
      <BottomNavigation
        className="main-content-nav-bar"
        showLabels={true}
        value={value}
        onChange={(event, newValue) => {
          updateCategoryId(newValue);
          setValue(newValue);
        }}
      >
        {subCategoriesState &&
          subCategoriesState.map((item) => (
            <BottomNavigationAction
              sx={getBottomsHeaderStyle}
              value={item._id}
              key={item._id}
              label={item.name}
            />
          ))}
      </BottomNavigation>

      <CategoriesDetails subCategoryId={value} />
    </div>
  );
};

export default MainContent;
