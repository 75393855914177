import { IconButton, InputBase, Paper } from "@mui/material";
import { React, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { SearchSubCategoriesDetails } from "../proxy/http.proxy";
import { setSearchTextResultsAction } from "../store/main.slice";
import { store } from "../store/store";
import { useSelector } from "react-redux";
import { GetTermSearchedSelector } from "../store/main.selector";

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const termSearched = useSelector(GetTermSearchedSelector);

  useEffect(() => {
    if (termSearched != undefined) {
      setSearchQuery(termSearched);
    }
  }, [termSearched]);

  const handleSearchChanged = (e) => {
    store.dispatch(setSearchTextResultsAction({ text: e.target.value }));
  };

  const handleSearchClick = () => {
    SearchSubCategoriesDetails(searchQuery);
  };

  return (
    <div className="searchBarContainer">
      <Paper
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          maxWidth: "400px",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          value={searchQuery}
          onChange={handleSearchChanged}
          placeholder="חפש באתר רנסנס"
          inputProps={{ "aria-label": "חפש באתר רנסנס" }}
        />
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="חפש"
          onClick={handleSearchClick}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </div>
  );
};

export default SearchBar;
