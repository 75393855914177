import React, { useEffect, useState } from "react";

import { Button, Divider, IconButton, TextField } from "@mui/material";
import { fontSize, styled, textAlign } from "@mui/system";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { toast } from "react-toastify";
import { AddContact } from "../proxy/http.proxy";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiOutlinedInput-input": {
    color: "white  !important",
  },
  "& .Mui-error": {
    color: "#ffd0d6 !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
      color: "white !important",
      width: "auto",
    },
    "&:hover fieldset": {
      borderColor: "white",
      color: "white",
      width: "auto",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
      color: "white",
      width: "auto",
    },
  },
});

const Footer = ({ isMobile, isFromDialog, _product }) => {
  const [name, setName] = useState("");
  const [isNameError, setIsNameError] = useState(false);
  const [phone, setPhone] = useState("");
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [city, setCity] = useState("");
  const [product, setProduct] = useState("");
  const [floor, setFloor] = useState("");
  const [measurements, setMeasurements] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (_product != undefined && _product != "") {
      setProduct(_product);
    }
  }, [_product]);

  const notifyPhone = () => toast.warn("נא להזין טלפון סלולרי תקין");
  const notifyName = () => toast.warn("נא הזן שם מלא ליצירת קשר");
  const notifySuccess = () =>
    toast.success("תודה על יצירת הקשר, נציגנו יצרו קשר בהקדם!");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);
  };
  const handleProductChange = (e) => {
    setProduct(e.target.value);
  };
  const handleFloorChange = (e) => {
    setFloor(e.target.value);
  };
  const handleMeasurementsChange = (e) => {
    setMeasurements(e.target.value);
  };
  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const isPhoneValid = (inputtxt) => {
    const phoneValidate = "^05[0-9]{8}$";

    if (inputtxt.match(phoneValidate)) {
      return true;
    } else {
      return false;
    }
  };

  const handleContact = () => {
    setIsNameError(false);
    setIsPhoneError(false);
    if (name == "") {
      notifyName();
      setIsNameError(true);
      return;
    } else if (isPhoneValid(phone) == false) {
      setIsPhoneError(true);
      notifyPhone();
      return;
    } else {
      const data = {
        name,
        phone,
        city,
        productNumber: product,
        floor: floor,
        measurements: measurements,
        customerRemarks: notes,
      };
      AddContact(data).then((res) => {
        notifySuccess();
        setName("");
        setPhone("");
        setCity("");
      });
    }
  };

  const getWidthByPlatform = () => {
    if (isFromDialog == true) {
      return "unset";
    } else {
      return "130px";
    }
  };

  return (
    <div
      className={`App-footer ${
        !isFromDialog ? "app-footer-background" : "app-footer-dialog"
      }`}
    >
      <CssTextField
        error={isNameError}
        id="name"
        size="small"
        label="שם מלא"
        variant="outlined"
        style={{ width: getWidthByPlatform() }}
        InputLabelProps={{ sx: { color: "white" } }}
        value={name}
        onChange={handleNameChange}
      />
      <CssTextField
        error={isPhoneError}
        id="phone"
        size="small"
        label="טלפון"
        variant="outlined"
        style={{ width: getWidthByPlatform() }}
        InputLabelProps={{ sx: { color: "white" } }}
        value={phone}
        onChange={handlePhoneChange}
      />

      {!isMobile && (
        <CssTextField
          id="city"
          size="small"
          label="עיר"
          variant="outlined"
          style={{ width: getWidthByPlatform() }}
          InputLabelProps={{ sx: { color: "white" } }}
          value={city}
          onChange={handleCityChange}
        />
      )}

      {!isMobile && (
        <CssTextField
          id="product"
          size="small"
          label="מוצר / מק''ט"
          variant="outlined"
          style={{ width: getWidthByPlatform() }}
          InputLabelProps={{ sx: { color: "white" } }}
          value={product}
          onChange={handleProductChange}
        />
      )}

      {!isMobile && (
        <CssTextField
          id="floor"
          size="small"
          label="קומה"
          variant="outlined"
          style={{ width: getWidthByPlatform() }}
          InputLabelProps={{ sx: { color: "white" } }}
          value={floor}
          onChange={handleFloorChange}
        />
      )}

      {!isMobile && (
        <CssTextField
          id="measurements"
          size="small"
          label="מידות"
          variant="outlined"
          style={{ width: getWidthByPlatform() }}
          InputLabelProps={{ sx: { color: "white" } }}
          value={measurements}
          onChange={handleMeasurementsChange}
        />
      )}

      {!isMobile && (
        <CssTextField
          id="notes"
          size="small"
          label="הערות כלליות"
          variant="outlined"
          style={{ width: getWidthByPlatform() }}
          InputLabelProps={{ sx: { color: "white" } }}
          value={notes}
          onChange={handleNotesChange}
        />
      )}

      <Button
        variant="contained"
        size="small"
        startIcon={<ContactPhoneIcon />}
        onClick={handleContact}
      >
        צור קשר
      </Button>
    </div>
  );
};

export default Footer;
