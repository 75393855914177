import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Categories: {},
  SubCategories: {},
  SubCategoriesDetailsForRoutes: {},
  SubCategoriesDetails: {},
  currentSubCategoryId: "",
  isContactsDialogOpen: false,
  Settings: {
    isItemDialogOpen: false,
    selectedItem: {},
  },
  Search: {
    termSearched: "",
    isSearchDialogOpen: false,
    SearchResults: [],
  },
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      const categories = action.payload;
      state.Categories = categories;
    },
    setSubCategories: (state, action) => {
      const subCategories = action.payload;
      state.SubCategories = subCategories;
    },
    setSubCategoriesDetails: (state, action) => {
      const subCategoriesDetails = action.payload;
      state.SubCategoriesDetails = subCategoriesDetails;
    },
    subCategoriesDetailsForRoutes: (state, action) => {
      const data = action.payload;
      state.SubCategoriesDetailsForRoutes = data;
    },
    setSettings: (state, action) => {
      const settings = action.payload;
      state.Settings = {
        ...state.Settings,
        ...settings,
      };
    },
    setCurrentSubCategoryId: (state, action) => {
      const { id } = action.payload;
      state.currentSubCategoryId = id;
    },
    setIsContactsDialogOpen: (state, action) => {
      const { open } = action.payload;
      state.isContactsDialogOpen = open;
    },
    setIsSearchDialogOpen: (state, action) => {
      const { open } = action.payload;
      state.Search = { ...state.Search, isSearchDialogOpen: open };
    },
    setSearchResults: (state, action) => {
      const { results } = action.payload;
      state.Search = {
        ...state.Search,
        SearchResults: results,
      };
    },
    setSearchTextResults: (state, action) => {
      const { text } = action.payload;
      state.Search = {
        ...state.Search,
        termSearched: text,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCategories: setCategoriesAction,
  setSubCategories: setSubCategoriesAction,
  setSubCategoriesDetails: setSubCategoriesDetailsAction,
  subCategoriesDetailsForRoutes: subCategoriesDetailsForRoutesAction,
  setSettings: setSettingsAction,
  setCurrentSubCategoryId: setCurrentSubCategoryIdAction,
  setIsContactsDialogOpen: setIsContactsDialogOpenAction,
  setIsSearchDialogOpen: setIsSearchDialogOpenAction,
  setSearchResults: setSearchResultsAction,
  setSearchTextResults: setSearchTextResultsAction,
} = mainSlice.actions;

export default mainSlice.reducer;
