import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  GetisItemDialogOpenSelector,
  GetSelectedItemSelector,
  GetSubCategoriesDetailsForRoutesSelector,
} from "../store/main.selector";
import { useSelector } from "react-redux";
import { store } from "../store/store";
import {
  setCurrentSubCategoryIdAction,
  setIsContactsDialogOpenAction,
  setSettingsAction,
} from "../store/main.slice";
import { getEmbeddFromUrl } from "../Helpers/utils";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Social from "../components/Social";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const BlinkedBox = styled("div")({
  animation: `${blink} 1.5s linear infinite`,
});

const CategoriesDetailsDialog = ({ id, isMobile }) => {
  const [open, setOpen] = useState(false);
  const [selectedItemState, setSelectedItemState] = React.useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isItemDialogOpen = useSelector(GetisItemDialogOpenSelector);

  const selectedItem = useSelector(GetSelectedItemSelector);
  const subCategoriesDetailsForRoutes = useSelector(
    GetSubCategoriesDetailsForRoutesSelector
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedItem != undefined) {
      setSelectedItemState(selectedItem);
      if (selectedItem.photos != undefined && selectedItem.photos.length > 0) {
        setSelectedImage(selectedItem.photos[0]);
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    if (isItemDialogOpen != undefined) {
      setOpen(isItemDialogOpen);
    }
  }, [isItemDialogOpen]);

  const handleClose = () => {
    navigate(-1);
  };

  const handleImageSelected = (item) => {
    setSelectedImage(item);
  };

  const handleContactDialog = () => {
    store.dispatch(setIsContactsDialogOpenAction({ open: true }));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="dialogHeaderContainer">
          <span> {selectedItemState.name}</span>
          <BlinkedBox>
            <Button
              color="info"
              size="large"
              variant="outlined"
              onClick={handleContactDialog}
            >
              קבל הצעת מחיר עכשיו!
            </Button>
          </BlinkedBox>
          {!isMobile && <Social />}

          <IconButton onClick={handleClose}>
            <ArrowBackIosNew />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="dialogContentContainer">
          <div>
            <span>
              תאור:
              <DialogContentText>{selectedItemState.details}</DialogContentText>
            </span>
            <br />
            <span>
              מק"ט:
              <DialogContentText>
                {selectedItemState.prodNumber}
              </DialogContentText>
            </span>
            <br />
            <span>
              כתובת התקנה:
              <DialogContentText>
                {selectedItemState.installAddress == null
                  ? "-"
                  : selectedItemState.installAddress}
              </DialogContentText>
            </span>
          </div>
          {isMobile && <Social />}

          <div className="dialogImagesContainer">
            {selectedImage && (
              <img
                className="dialogImageSelectedItem"
                alt="../loading-load.gif"
                src={getEmbeddFromUrl(selectedImage)}
                onError={(e) => {
                  e.target.src = "../loading-load.gif";
                }}
                loading="lazy"
              />
            )}
            <div className="dialogImagesListContainer">
              {selectedItemState &&
                selectedItemState.photos &&
                selectedItemState.photos.map((item, index) => (
                  <img
                    key={index}
                    onClick={(e) => handleImageSelected(item)}
                    className={
                      item == selectedImage
                        ? "dialogImagesListItemSelected"
                        : "dialogImagesListItem"
                    }
                    alt="../loading-load.gif"
                    src={getEmbeddFromUrl(item)}
                    onError={(e) => {
                      e.target.src = "../loading-load.gif";
                    }}
                    loading="lazy"
                  />
                ))}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default CategoriesDetailsDialog;
