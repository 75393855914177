import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  GetIsSearchDialogOpenSelector,
  GetSearchResultsSelector,
} from "../store/main.selector";
import { useSelector } from "react-redux";
import { store } from "../store/store";
import {
  setIsContactsDialogOpenAction,
  setIsSearchDialogOpenAction,
} from "../store/main.slice";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, IconButton, useMediaQuery } from "@mui/material";
import Footer from "../components/Footer";
import OneCategoryDetail from "../components/OneCategoryDetail";
import SearchBar from "../components/SearchBar";
import { useTheme } from "@mui/material/styles";

const SearchDialog = ({ isMobile }) => {
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isSearchDialogOpen = useSelector(GetIsSearchDialogOpenSelector);
  const searchResults = useSelector(GetSearchResultsSelector);

  useEffect(() => {
    if (isSearchDialogOpen != undefined) {
      setOpen(isSearchDialogOpen);
    }
  }, [isSearchDialogOpen]);

  useEffect(() => {
    if (searchResults != undefined) {
      setResults(searchResults);
    }
  }, [searchResults]);

  const handleClose = () => {
    store.dispatch(setIsSearchDialogOpenAction({ open: false }));
  };

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      maxWidth="lg"
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div className="dialogSearchBarHeaderContainer">
          <span style={{ marginRight: "10px" }}> תוצאות חיפוש </span>

          <SearchBar />
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider sx={{ width: "99%", m: 0.5 }} orientation="horizontal" />
      <DialogContent>
        <div className="dialogContentContainer">
          <div className="image-gallery">
            {results && <span>נמצאו {results.length} תוצאות</span>}
            <div className="column">
              {results &&
                results.map((item) => (
                  <OneCategoryDetail key={item._id} item={item} />
                ))}
              {results.length == 0 && <span>לא נמצאו תוצאות</span>}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SearchDialog;
