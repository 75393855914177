import { React } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Instagram from "@mui/icons-material/Instagram";
import Facebook from "@mui/icons-material/Facebook";
import PhoneIcon from "@mui/icons-material/Phone";
import { Divider, IconButton } from "@mui/material";

const Social = () => {
  return (
    <div className="display-flex" style={{ alignSelf: "center" }}>
      <Divider orientation="vertical" flexItem />
      <IconButton
        sx={{
          color: "#E1306C",
        }}
        size="large"
        // href="https://wa.me/972503322788"
        href="https://instagram.com/avibenyaish?igshid=MWI4MTIyMDE="
        target="_blank"
      >
        <Instagram />
      </IconButton>
      <Divider orientation="vertical" flexItem />
      <IconButton
        sx={{ color: "#3b5998" }}
        size="large"
        href="https://www.facebook.com/renesanseHOMEOUTDOORDESIGN?mibextid=ZbWKwL"
        target="_blank"
      >
        <Facebook />
      </IconButton>

      <Divider orientation="vertical" flexItem />
      <IconButton
        sx={{ color: "#00c64a" }}
        size="large"
        // href="https://wa.me/972503322788"
        href="https://wa.me/message/K35IF7UBNEAXO1"
        target="_blank"
      >
        <WhatsAppIcon />
      </IconButton>

      <Divider orientation="vertical" flexItem />
      <IconButton
        sx={{ color: "#40b1ef" }}
        size="large"
        href="tel:0503322788"
        target="_blank"
      >
        <PhoneIcon />
      </IconButton>
      <Divider orientation="vertical" flexItem />
    </div>
  );
};

export default Social;
