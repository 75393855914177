import { CardContent, Divider, Typography } from "@mui/material";
import React from "react";
import homeBG from "../images/home-bg.jpg";
import homeJsonData from "../configuration/home.config.json";
import SearchBar from "./SearchBar";

const Home = () => {
  return (
    <div className="home-container">
      <Divider sx={{ width: "99%", m: 0.5 }} orientation="horizontal" />
      <SearchBar />
      <img className="width-100" src={homeBG} />

      {homeJsonData && (
        <CardContent>
          {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Word of the Day
            </Typography> */}
          <Typography variant="h5" component="div">
            {homeJsonData.header}
          </Typography>

          {homeJsonData.paragraphs.map((text, index) => (
            <Typography
              key={index}
              variant="body2"
              sx={{ width: "80%", margin: "auto" }}
            >
              <br />
              {text}
            </Typography>
          ))}
        </CardContent>
      )}
    </div>
  );
};

export default Home;
